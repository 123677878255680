<template>
  <div class="d-flex justify-content-center align-items-center min-vh-100">
    <c-card class="p-4">
      <c-card-body>
        <c-form @submit.prevent="submit">
          <h1>Login</h1>
          <p class="text-muted">Sign in to your account</p>
          <c-input
            v-model="form.login"
            placeholder="Username"
            autocomplete="username email"
          >
            <template #prepend-content>
              <c-icon name="cil-user" />
            </template>
          </c-input>
          <c-input
            v-model="form.pass"
            placeholder="Password"
            type="password"
            autocomplete="password"
          >
            <template #prepend-content>
              <c-icon name="cil-lock-locked" />
            </template>
          </c-input>
          <c-button type="submit" color="primary" class="px-4" block>
            Login
          </c-button>
        </c-form>
      </c-card-body>
    </c-card>
  </div>
</template>

<script>
export default {
  name: 'Login',

  data: () => ({
    form: {
      login: '',
      pass: ''
    }
  }),

  watch: {
    '$store.state.user.id': {
      handler(id) {
        if (!id) return

        const { goto } = this.$route.query
        delete this.$route.query.goto

        const path = goto ? decodeURIComponent(goto) : '/'
        this.$router.replace({ path, query: this.$route.query })
      },
      immediate: true
    }
  },

  methods: {
    async submit() {
      await this.$store.dispatch('user/login', this.form)
    }
  }
}
</script>
